exports.shouldUpdateScroll = ({
  routerProps: { location },
  // eslint-disable-next-line no-unused-vars
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  // セッションを表示する時だけスクロールをしない。
  if (pathname.match(/timetable\/\d+\/?$/)) {
    return false;
  }

  return true;
};
