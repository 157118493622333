exports.components = {
  "component---src-helpers-pages-timetable-detail-page-tsx": () => import("./../../../src/helpers/pages/timetable/DetailPage.tsx" /* webpackChunkName: "component---src-helpers-pages-timetable-detail-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-staffs-tsx": () => import("./../../../src/pages/staffs.tsx" /* webpackChunkName: "component---src-pages-staffs-tsx" */),
  "component---src-pages-timetable-day-1-tsx": () => import("./../../../src/pages/timetable/day1.tsx" /* webpackChunkName: "component---src-pages-timetable-day-1-tsx" */),
  "component---src-pages-timetable-day-2-tsx": () => import("./../../../src/pages/timetable/day2.tsx" /* webpackChunkName: "component---src-pages-timetable-day-2-tsx" */),
  "component---src-pages-timetable-day-3-tsx": () => import("./../../../src/pages/timetable/day3.tsx" /* webpackChunkName: "component---src-pages-timetable-day-3-tsx" */),
  "component---src-pages-timetable-tsx": () => import("./../../../src/pages/timetable.tsx" /* webpackChunkName: "component---src-pages-timetable-tsx" */)
}

